export const VALIDGUESSES = ['march',
'train',
'llama',
'block',
'ruler',
'romer',
'trock',
'azaad',
'lewis',
'power',
'group',
'learn',
'spats',
'glade',
'globe',
'class',
'romeo',
'olney',
'mlkjr',
'abbie',
'skill',
'betty',
'koret',
'tacos',
'pizza',
'calif',
'zebra',
'field',
'atlas',
'yopos',
'glenn',
'bauer',
'ocean',
'prize',
'nader',
'twain',
'peets',
'calid',
'kipss',
'comal',
'halls',
'adams',
'duper',
'jamba',
'basic',
'greek',
'edith',
'nobel',
'award',
'peace',
'gypsy',
'lands',
'ashby',
'staff',
'moses',
'frank',
'plaza',
'starr',
'yalis',
'build',
'hesse',
'cesar',
'lower',
'break',
'grads',
'james',
'union',
'reich',
'missy',
'leroy',
'cream',
'minor',
'birge',
'harry',
'keach',
'getty',
'derby',
'trees',
'north',
'noahs',
'blaze',
'takei',
'shing',
'philz',
'akpsi',
'world',
'blake',
'jason',
'loans',
'haste',
'kiwis',
'stacy',
'stern',
'topic',
'apple',
'caffe',
'croad',
'margs',
'chalk',
'think',
'tests',
'mezzo',
'piano',
'bongo',
'peers',
'bmail',
'beers',
'forum',
'scott',
'party',
'bully',
'evans',
'hoffs',
'aaron',
'rally',
'simon',
'cnmat',
'susan',
'aftel',
'lynch',
'house',
'frats',
'mater',
'alums',
'teach',
'dance',
'davis',
'weill',
'super',
'units',
'carol',
'yahya',
'vodka',
'clubs',
'grant',
'south',
'upper',
'pupil',
'alamo',
'fucla',
'smart',
'chain',
'jimmy',
'bixby',
'steve',
'profs',
'brien',
'leary',
'major',
'tbell',
'light',
'music',
'polio',
'cheit',
'bears',
'naked',
'fauci',
'julia',
'regal',
'chris',
'songs',
'phase',
'hertz',
'child',
'pappy',
'paula',
'ellis',
'stole',
'deans',
'grade',
'bamfa',
'clark',
'ganja',
'study',
'fairs',
'fires',
'exams',
'faang',
'psych',
'suite',
'stats',
'decal',
'memes']