export const WORDS = ['llama',
'romer',
'trock',
'spats',
'brien',
'romeo',
'olney',
'mlkjr',
'zebra',
'yopos',
'bauer',
'nader',
'peets',
'calid',
'kipss',
'comal',
'nobel',
'gypsy',
'ashby',
'starr',
'yalis',
'cesar',
'reich',
'cream',
'birge',
'derby',
'noahs',
'blaze',
'takei',
'shing',
'philz',
'blake',
'haste',
'kiwis',
'stern',
'caffe',
'croad',
'mezzo',
'bongo',
'bmail',
'evans',
'davis',
'weill',
'units',
'carol',
'glade',
'fucla',
'bixby',
'tbell',
'leary',
'grant',
'cheit',
'bears',
'regal',
'chris',
'hertz',
'aaron',
'lynch',
'pappy',
'ellis',
'bamfa',
'clark',
'ganja',
'fires',
'faang',
'decal',
'lewis',
'memes']